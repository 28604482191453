@import '~antd/dist/antd.less';

.wrapper {
  text-align: right;
}

.callout {
  margin: 60px 0;
  padding: 25px 40px;
  background: @primary-color;
  border-radius: 25px;
  display: inline-block;
  position: relative;

  &::before {
    font-size: 76px;
    position: absolute;
  }

  em {
    font-style: normal;
  }

  &.fire {
    &::before {
      content: '🔥';
      bottom: -25px;
      left: 15px;
    }

    em {
      background: linear-gradient(180deg, #FF4E4E 20%, rgba(255, 214, 0, 0.81) 75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  &.target {
    &::before {
      content: '🎯';
      top: 20px;
      left: 30px;
    }

    em {
      color: #69EAF2;
    }
  }

  &.money {
    &::before {
      content: '💶';
      top: -5px;
      left: 30px;
      rotate: -15deg;
    }

    em {
      color: #A3EC6A;
    }
  }

  @media (max-width: @screen-lg-max) {
    padding: 15px 25px;
    border-radius: 20px;

    &::before {
      font-size: 45px;
    }

    &.fire::before {
      bottom: -14px;
    }

    &.target::before {
      top: 16px;
      left: 25px;
    }
  }
}

:global(.ant-typography).text {
  color: white;
  margin: 0;
  text-align: right;
  line-height: 1.5em;

  @media (max-width: @screen-lg-max) {
    font-size: 22px;
  }
}

@primary-color: #5D5FEF;@layout-header-background: #5D5FEF;@font-family: suw, Roboto, 'Helvetica Neue', Arial,  'Noto Sans', sans-serif;@body-background: white;@text-color: #4D4D6C;@heading-color: #4D4D6C;@text-color-secondary: #5D5FEF;