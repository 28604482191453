@import "./layout.m";

.text {
  font-weight: normal !important;
  padding: 40px;

  &.right {
    text-align: right;
  }
}

.image {
  width: 100%;
}

.textContainer {
  display: flex;
  align-items: center;

  &.right {
    justify-content: right;
  }
}

.extraLeft {
  @media (max-width: @screen-lg-max) {
    .line;
    .line.left;
    padding-left: 20px;
  }
}

.extraRight {
  @media (max-width: @screen-lg-max) {
    .line;
    .line.right;
    padding-right: 20px;
  }
}

@primary-color: #5D5FEF;@layout-header-background: #5D5FEF;@font-family: suw, Roboto, 'Helvetica Neue', Arial,  'Noto Sans', sans-serif;@body-background: white;@text-color: #4D4D6C;@heading-color: #4D4D6C;@text-color-secondary: #5D5FEF;