@import '~antd/dist/antd.less';

.how {
  position: relative;
  padding-top: 40px;

  .line {
    position: absolute;
    height: 100%;
    left: calc(50% - 1px);
    top: 0;
  }

  .text {
    position: relative;
    left: 1.5px;
  }

  .point {
    top: calc(50% + 15px);
    height: 14px;

    @media (max-width: @screen-lg-max) {
      top: calc(50% + 44px);
    }
  }
}

@primary-color: #5D5FEF;@layout-header-background: #5D5FEF;@font-family: suw, Roboto, 'Helvetica Neue', Arial,  'Noto Sans', sans-serif;@body-background: white;@text-color: #4D4D6C;@heading-color: #4D4D6C;@text-color-secondary: #5D5FEF;