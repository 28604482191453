@import '~antd/dist/antd.less';

:global(.ant-typography).text {
  font-weight: normal;
  padding-left: 80px;
  line-height: 1em;
  margin-bottom: 0;

  @media (max-width: @screen-lg-max) {
    padding-left: 40px;
    font-size: 20px;
    line-height: 1.5em;
  }
}

.step {
  position: relative;
  padding: 25px 0;
}

@primary-color: #5D5FEF;@layout-header-background: #5D5FEF;@font-family: suw, Roboto, 'Helvetica Neue', Arial,  'Noto Sans', sans-serif;@body-background: white;@text-color: #4D4D6C;@heading-color: #4D4D6C;@text-color-secondary: #5D5FEF;