@import '~antd/dist/antd.less';

.layout {
  background: white;
}

.header {
  padding: 0 20px;
}

.widthLimiter {
  max-width: @screen-xl;
  margin: auto;
}

.logo {
  height: @layout-header-height;
  width: @layout-header-height;
  background-image: url("../../public/Logo.svg");
  background-size: contain;
  position: relative;
  top: calc(@layout-header-height / 2);
  float: left;
}

:global(.ant-typography).title {
  color: white;
  line-height: @layout-header-height;
  padding-left: calc(@layout-header-height + 20px);
  font-weight: normal;
}


:global(.ant-typography).sectionTitle {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: @screen-lg-max) {
    margin-top: 80px;
    margin-bottom: 20px;
    font-size: 26px;
  }
}

.line {
  border-color: @primary-4;
  border-style: solid;

  &.bottomRight {
    border-width: 0 1px 1px 0;
    border-radius: 0 0 20px 0;
    margin-bottom: -1px;
  }

  &.bottomLeft {
    border-width: 0 0 1px 1px;
    border-radius: 0 0 0 20px;
    margin-bottom: -1px;
  }

  &.topLeft {
    border-width: 1px 0 0 1px;
    border-radius: 20px 0 0 0;
  }

  &.topRight {
    border-width: 1px 1px 0 0;
    border-radius: 0 20px 0 0;
  }

  &.left {
    border-width: 0 0 0 1px;
  }

  &.right {
    border-width: 0 1px 0 0;
  }
}

@pointSize: 11px;

.point {
  width: @pointSize;
  height: @pointSize;
  border: 1px solid @primary-4;
  border-radius: 50%;
  background: white;

  &.large {
    width: 21px;
    height: 21px;
    position: relative;

    &::before {
      position: absolute;
      width: 15px;
      height: 15px;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      background: @primary-4;
      content: '';
    }
  }

  &.topRight {
    position: absolute;
    top: -6px;
    right: -6px;
  }

  &.bottomRight {
    position: absolute;
    bottom: -6px;
    right: -6px;
  }

  &.middleLeft {
    position: absolute;
    top: calc(50% - 6px);
    left: -6px;

    &.large {
      left: -11px;
      top: calc(50% - 11px);
    }
  }

  &.middleRight {
    position: absolute;
    top: calc(50% - 6px);
    right: -6px;
  }

  &.middle {
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
  }
}

.transparentText {
  color: white;
  background: @primary-color;
  padding: 0 10px;
  letter-spacing: 0.1em;
  line-height: 2em;
  font-weight: bold;
}

.footer {
  background: @primary-color;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 150px;
  align-items: center;

  .footerLogo {
    height: 50px;
    aspect-ratio: 1 / 1;
    background-image: url("../../public/Logo.svg");
    background-size: contain;
  }

  :global(.ant-typography).footerTitle {
    color: white;
    font-weight: normal;
    padding-left: 20px;
    margin: 0;
  }

  @media (max-width: @screen-lg-max) {
    padding-bottom: 50px;

    .footerLogo {
      height: 30px;
    }

    :global(.ant-typography).footerTitle {
      font-size: 20px;
    }
  }
}

@primary-color: #5D5FEF;@layout-header-background: #5D5FEF;@font-family: suw, Roboto, 'Helvetica Neue', Arial,  'Noto Sans', sans-serif;@body-background: white;@text-color: #4D4D6C;@heading-color: #4D4D6C;@text-color-secondary: #5D5FEF;