@import '~antd/dist/antd.less';

.plan {
  background: @primary-color;
  border-radius: 20px;
  padding: 20px 30px;
  height: 100%;
}

.features {
  color: white;
  padding-left: 20px;
  margin-top: 20px;

  li {
    padding-bottom: 5px;
  }
}

:global(.ant-typography) {
  &.text {
    color: white;
    margin-top: 0 !important;
  }

  &.price {
    margin-top: .5em !important;
    margin-bottom: 0;
  }
}

.gridContainer {
  @media (max-width: @screen-lg-max) {
    display: none;
  }

  .gridRowContainer {
    padding-bottom: 200px;
  }
}

.carouselContainer {
  display: none;

  @media (max-width: @screen-lg-max) {
    display: flex;
  }
}

.carousel {
  background: @primary-color;
  border-radius: 20px;
  padding: 20px 0;
  margin-bottom: 200px;
}

@primary-color: #5D5FEF;@layout-header-background: #5D5FEF;@font-family: suw, Roboto, 'Helvetica Neue', Arial,  'Noto Sans', sans-serif;@body-background: white;@text-color: #4D4D6C;@heading-color: #4D4D6C;@text-color-secondary: #5D5FEF;