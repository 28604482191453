@import '~antd/dist/antd.less';

.winWin {
  background: @primary-3;

  .logo {
    margin: 5%;
  }

  .winText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .leftLogo {
    background: white;
    color: @primary-color;
    aspect-ratio: 1 / 2;
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
  }

  .rightLogo {
    aspect-ratio: 1 / 2;
    background: @primary-color;
    color: white;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  }

  .sloganLeft {
    display: inline-flex;
    align-items: center;
    justify-content: right;
  }

  .sloganRight {
    display: inline-flex;
    align-items: center;
  }

  .orbit {
    border: 1px solid @primary-4;
    border-radius: 50%;
    max-width: 360px;
  }

  .text {
    font-weight: normal !important;
    padding: 40px;

    &.right {
      text-align: right;
    }
  }

  @media (max-width: @screen-lg-max) {
    .padding {
      display: none;
    }

    .sloganLeft {
      justify-content: center;
    }

    .sloganRight {
      justify-content: center;
    }

    .text {
      text-align: center;

      &.right {
        text-align: center;
      }
    }
  }
}

@primary-color: #5D5FEF;@layout-header-background: #5D5FEF;@font-family: suw, Roboto, 'Helvetica Neue', Arial,  'Noto Sans', sans-serif;@body-background: white;@text-color: #4D4D6C;@heading-color: #4D4D6C;@text-color-secondary: #5D5FEF;